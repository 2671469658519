import React from 'react'
// Components
import ProfileCard from 'components/UIElements/Card/ProfileCard'
// Models
import { IProfileCard } from 'models/Profile'
import { IArtistCard } from 'models/Artist'
// Styles
import * as styles from './ArtistsList.module.css'

type ArtistsListProps = {
    artists: IArtistCard[]
}

const ArtistsList: React.FC<ArtistsListProps> = ({ artists }) => {
    return (
        <div className={styles.artists_list}>
            {artists.map((artist) => {
                const subname = artist.alias ? (
                    <>
                        Otros nombres:{' '}
                        {artist.alias.map((a, index) => (
                            <small key={a}>
                                {index + 1 < artist.alias!.length
                                    ? `${a}, `
                                    : a}
                            </small>
                        ))}
                    </>
                ) : (
                    <></>
                )
                const profileCardProps: IProfileCard = {
                    name: artist.name,
                    subname: subname,
                    avatar: artist.avatar,
                    cover: artist.cover,
                    to: `/artists/${artist.slug}`,
                }
                // <UserCard user={user} />
                return <ProfileCard {...profileCardProps} />
            })}
        </div>
    )
}

export default ArtistsList

import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IArtist } from 'models/Artist'
import ArtistsContainer from 'components/Sections/Artists/ArtistsContainer'
import { IContextWithPageNav } from 'models/Context'

type ArtistPageProps = {
    artists: {
        nodes: IArtist[]
    }
    pageSeo: ISeoQuery
}

const ArtistPage: React.FC<PageProps<ArtistPageProps, IContextWithPageNav>> = ({
    data,
    pageContext: context,
}) => {
    const artists = data.artists.nodes
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }
    return (
        <Layout seo={seo}>
            <SectionHeader title={pageSeo.title} image={pageSeo.image} />
            <ArtistsContainer context={context} artists={artists} />
        </Layout>
    )
}

export default ArtistPage

export const pageQuery = graphql`
    query ArtistsListPage($limit: Int!, $skip: Int!) {
        artists: allContentfulArtists(limit: $limit, skip: $skip) {
            nodes {
                name
                slug
                alias

                avatar {
                    gatsbyImageData(width: 130)
                }
                cover {
                    gatsbyImageData(width: 550)
                }
            }
        }
        pageSeo: contentfulSeo(slug: { eq: "artists" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`

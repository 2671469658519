import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import ArtistsList from './ArtistsList'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { IContextWithPageNav } from 'models/Context'
import { IArtistCard } from 'models/Artist'
// Styles
import * as styles from './ArtistsContainer.module.css'

type ArtistsContainerProps = {
    artists: IArtistCard[]
    context: IContextWithPageNav
}

const ArtistsContainer: React.FC<ArtistsContainerProps> = ({
    artists,
    context,
}) => {
    const { currentPage, totalPages } = context
    return (
        <section className={styles.artists}>
            <Content>
                <ArtistsList artists={artists} />
                <PageNav
                    to="/artists"
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </Content>
            <DefaultSidebar />
        </section>
    )
}

export default ArtistsContainer
